import React from 'react';
import {gql} from "apollo-boost";
import {useQuery} from "@apollo/react-hooks";
import SearchResultDisplayCard from "./SearchResultDisplayCard";
import Grid from "@material-ui/core/Grid";


const QUERY = gql`
    query Query($query: String!, $classifier: String!){
        webSearch(query: $query) {
            name {
                text
            }
            snippet {
                text
                classify(hint: $classifier) {
                    overallScore
                }
            }
            url {
                url
            }
        }
    }
`;

const SearchAndClassifyInner = (props: { query: string, classifier: string }) => {
    const {query, classifier} = props;
    const {loading, error, data} = useQuery(QUERY, {
        variables: {query, classifier}
    });

    if (loading) return <p/>;
    if (error) return <p>{error}</p>;
    console.log(data);

    return (
        <Grid container justify="center" alignItems="center" spacing={2}>
            {
                data.webSearch.map((res: any) => {
                    return (
                        <Grid item>
                            <SearchResultDisplayCard result={res}/>
                        </Grid>
                    )
                })
            }
        </Grid>
    )
};

const SearchAndClassify = (props: { query: string, classifier: string }) => {
    if (props.query.length * props.classifier.length === 0) {
        return <div/>;
    }
    return <SearchAndClassifyInner {...props}/>
};

export default SearchAndClassify;