import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Divider from "@material-ui/core/Divider";
import {Grid} from "@material-ui/core";

const useStyles = makeStyles({
    root: {
        minWidth: 1000,
        maxWidth: 1000
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    greenBg: {
        background: "#4EA8FC"
    },
    redBg: {
        background: '#FFDB5C'
    }
});

export default function SearchResultDisplayCard(props: any) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Card>
                <CardContent>
                    <Grid
                        container
                        direction="row"
                        spacing={1}
                    >
                        <Grid item>
                            <div>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                    Snippet
                                </Typography>
                                <Typography variant="body2" component="p">
                                    {props.result.snippet.text}
                                </Typography>
                                <br/>
                                <Divider/>
                                <br/>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                    URL
                                </Typography>
                                <Typography variant="body2" component="p">
                                    {props.result.url.url}
                                </Typography>
                                <Typography className={props.result.snippet.classify.overallScore > .5 ? classes.greenBg: classes.redBg}>
                                    {props.result.snippet.classify.overallScore}
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </div>
    );
}