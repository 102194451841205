import React, {useState} from 'react';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

interface iSourceSelectionsProps {
    onSubmit: (query: string, classifier: string) => void
}

const SourceSelections = (props: iSourceSelectionsProps) => {
    const [query, setQuery] = useState("");
    const [classifier, setClassifier] = useState("");
    return (
        <div>
            <Grid container justify="center" alignItems="center" spacing={2}>
                <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                    <Grid item>
                        <TextField
                            label="Web Search"
                            value={query}
                            onChange={e => setQuery(e.target.value)}
                            id="filled-basic"
                            variant="filled"
                            size={'small'}
                            inputProps={{size: 50}}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            label="Instructions"
                            value={classifier}
                            onChange={e => setClassifier(e.target.value)}
                            id="filled-basic"
                            variant="filled"
                            size={'small'}
                            inputProps={{size: 50}}
                        />
                    </Grid>
                </Grid>
                <Grid item>
                    <Button
                        onClick={() => {
                            if (query.length * classifier.length === 0) {
                                return;
                            }
                            props.onSubmit(query, classifier);
                        }}
                        variant="contained"
                        color="primary"
                    >Submit</Button>
                </Grid>
            </Grid>
        </div>

    )
};

export default SourceSelections;
