import React, {useState} from "react";
import Navigation from "./components/Navigation";
import {createStyles, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import SourceSelections from "./components/SourceSelections";
import SearchAndClassify from "./components/SearchAndClassify";
import Card from "@material-ui/core/Card";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
        },
        toolbar: theme.mixins.toolbar,
    }),
);


const App = () => {
    const classes = useStyles();
    const [query, setQuery] = useState("");
    const [classifier, setClassifier] = useState("");
    return (
        <div className={classes.root}>
            <Navigation/>
            <main className={classes.content}>
                <div className={classes.toolbar}/>
                <Grid container justify="center" spacing={1}>
                    <Grid item>
                        <Card>
                            <Box m={5}>
                                <SourceSelections
                                    onSubmit={(q, c) => {
                                        setQuery(q);
                                        setClassifier(c);
                                    }}
                                />
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item>
                        <SearchAndClassify
                            query={query}
                            classifier={classifier}
                        />
                    </Grid>
                </Grid>
            </main>
        </div>
    )
};

export default App;
