import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import {Paper} from "@material-ui/core";
import Card from "@material-ui/core/Card";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            background: "#B3CDFF",
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
            background: "#182247",
        },
        crossColor: {
            width: drawerWidth,
            background: "#3166CE",
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
        },
        listItem: {
            color: "#FFFFFF",
        },
        toolbar: theme.mixins.toolbar,
    }),
);

export default function ClippedDrawer() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CssBaseline/>
            <AppBar elevation={0} position="fixed" className={classes.appBar}>
                <Toolbar className={classes.crossColor}>
                    <Typography variant="h4" noWrap>
                        Relevance
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.toolbar}/>
                <List className={classes.listItem}>

                </List>
            </Drawer>
        </div>
    );
}