import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {InMemoryCache} from 'apollo-cache-inmemory';
import * as serviceWorker from './serviceWorker';
import {ApolloProvider} from '@apollo/react-hooks';

import ApolloClient from 'apollo-boost';

const AppWithApollo = () => {
    const client = new ApolloClient({
        uri: 'https://graph.nlp.dev.styloml.com/',
        cache: new InMemoryCache(),
        request: (operation) => {
            const token = '33abb30a-13da-4fc5-b84f-a9bf99346fcf';
            operation.setContext({
                headers: {
                    authorization: token
                }
            })
        }
    });
    return (
        <ApolloProvider client={client}>
            <App/>
        </ApolloProvider>
    )
};

ReactDOM.render(<AppWithApollo/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
